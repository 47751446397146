import $ from 'jquery'
import TwoFactor from '../twoFactor'

export default class otpInput {
  constructor (element) {

    this.$element = $(element);
    this.$originalInput = this.$element.find('input:first')
    this.$originalInput.attr('type', 'hidden')

    this.$form = this.$element.closest('form')

    this.twoFactor = new TwoFactor(this.$element.get(0), 6, this.$form.get(0), this.$originalInput.get(0));
    this.twoFactor.render();
  }

  destroy() {
  }

}
