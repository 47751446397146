import $ from 'jquery'

export default class dataTable {
  constructor (element) {
    this.$element = $(element)
    this.id = `datatable_${this.makeid(10)}`
    this.$element.attr('id', this.id)

    this.datatableConfig = this.$element.data('datatableConfig')
    if (!this.datatableConfig) {
      this.datatableConfig = {
        scrollX: true
      }
    }

    this.DataTable = null
    this.libButtonsLoaded = null
    this.libButtonsHTMLLoaded = true
    this.libResponsiveLoaded = null

    import(/* webpackChunkName: "datatables" */ 'datatables.net-buttons')
    import(/* webpackChunkName: "datatables" */ 'datatables.net-buttons-bs5')
    import(/* webpackChunkName: "datatables" */ 'datatables.net-buttons/js/buttons.html5')
    import(/* webpackChunkName: "datatables" */ 'datatables.net-responsive')
    import(/* webpackChunkName: "datatables" */ 'datatables.net-responsive/js/dataTables.responsive')
    import(/* webpackChunkName: "datatables" */ 'datatables.net-plugins/sorting/date-eu')
    import(/* webpackChunkName: "datatables" */ 'datatables.net-bs5').then((module) => {
      this.DataTable = module.default
      this.initialize()
    })
  }

  makeid (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
  }

  initialize () {
    this.table = new this.DataTable(`#${this.id}`, this.datatableConfig)
  }
}
