import $ from 'jquery'

export default class dashboard {

    constructor (element) {
      this.$widget = $(element)
      this.$charts = $('.chart', element)

      // dynamisches laden von chartjs
      this.ChartJSLib = null
      import(/* webpackChunkName: "chartjs" */ 'chart.js').then((module) => {

        this.ChartJSLib = module.Chart
        this.ChartJSLib.register(...module.registerables);

        this.$charts.each(function (index, item) {
          this.initializeChart($(item))
        }.bind(this))

      }).catch(e => {
        console.error(e)
      });
    }

    initializeChart ($chart) {
        const $ctx = $chart.find('canvas')
        const chartData = $chart.data('chartData')
        const chartType = $chart.data('chartType')
        const options = this.getOptionsForType($chart.data('chartOption'))

        options.title.text = $chart.data('chartTitle')

        const chartSetting = {
            type: chartType,
            data: {
                labels: [],
                datasets: []
            },
            options: options
        }

        const myChart = new this.ChartJSLib($ctx.get(0), chartSetting)

        myChart.data.labels = chartData.labels
        myChart.data.datasets = chartData.datasets // or you can iterate for multiple datasets
        myChart.update() // finally update our chart
    }

    getOptionsForType (optionType) {
        let options = {
            title: {
                display: false
            },
            tooltips: {
                mode: 'index',
                intersect: true
            },
            responsive: true,
            maintainAspectRatio: false
        }

        switch (optionType) {
            case 'stackedBar':
            case 'stackedLine':
            case 'filledStackedLine':
                options.scales = {
                    x: {
                        stacked: true
                    },
                    y: {
                        stacked: true
                    }
                }
        }

        switch (optionType) {
            case 'bar':
            case 'stackedBar':
                break
            case 'line':
            case 'stackedLine':
                options.elements = {
                    point: {
                        radius: 3
                    }
                }
                break
            case 'filledLine':
            case 'filledStackedLine':
                options.plugins = {
                    filler: {
                        propagate: false
                    },
                    'samples-filler-analyser': {
                        target: 'chart-analyser'
                    }
                }
                break
        }

        return options
    }











    // constructor (element) {
    //     // this.$widget = $(element)
    //     // this.$charts = $('.chart', element)
    //     // this.$charts.each(function (index, item) {
    //     //     this.initializeChart($(item))
    //     // }.bind(this))
    //
    //     // const ctx = document.getElementById('myChart');
    //
    //     console.log(element)
    //
    //     new Chart(element, {
    //         type: 'pie',
    //         data: {
    //             labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    //             datasets: [{
    //                 label: '# of Votes',
    //                 data: [12, 19, 3, 5, 2, 3],
    //                 borderWidth: 1
    //             }]
    //         },
    //         options: {
    //             scales: {
    //                 y: {
    //                     beginAtZero: true
    //                 }
    //             }
    //         }
    //     });
    // }
}
